<template>
  <div>
    <div class="Joinus" v-if="pc == 'pc'">
      <Nav titlename="Joinus" @jumpMenu="jumpMenu"></Nav>
      <Banner
        :pic="bannerimg"
        :title="bannertitle"
        :content="bannercontent"
      ></Banner>
      <div class="recruitbox">
        <div class="recruitbox-left">
          <div class="recruitbox-left-title">{{ requireposition.title }}</div>
          <div
            class="recruitbox-left-item"
            v-for="(item, index) in requireposition.content"
            :key="index"
            @click="choosethis(item)"
          >
            <div
              class="recruitbox-left-itembox"
              :style="{
                backgroundColor: choose == item.status ? '#FAFAFA' : '#fff',
              }"
            >
              <div :class="choose == item.status ? 'positiontop' : 'position'">
                {{ item.jobname }}
              </div>
              <div class="topicon">
                <img
                  :src="
                    choose == item.status
                      ? '//bosdev.liangyihui.net/temp/1617171710.png'
                      : '//bosdev.liangyihui.net/temp/1617173611.png'
                  "
                  alt=""
                  class="iconimg"
                />
              </div>
            </div>
          </div>
          <div class="recruitbox-left-button" @click="toseemore">
            查看更多岗位
          </div>
        </div>
        <div v-for="(item, index) in requireposition.content" :key="index">
          <div class="recruitbox-right" v-if="choose == item.status">
            <div class="recruitbox-right-topbox">
              <div class="recruitbox-right-job">{{ item.jobname }}</div>
              <div class="recruitbox-right-button" @click="toseemore">
                申请岗位
              </div>
            </div>
            <div class="line"></div>
            <div class="requirebox">
              <div class="requirebox-line"></div>
              <div class="requirebox-font">岗位职责</div>
            </div>
            <div class="requirecontent">
              <div v-for="(item1, index1) in item.duty" :key="index1">
                {{ item1 }}
              </div>
            </div>
            <div class="requirebox">
              <div class="requirebox-line"></div>
              <div class="requirebox-font">岗位要求</div>
            </div>
            <div class="requirecontent">
              <div v-for="(item2, index2) in item.require" :key="index2">
                {{ item2 }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
    <div v-if="pc == 'mobil'" class="mbJoinus">
      <Nav titlename="Joinus" @jumpMenu="jumpMenu"></Nav>
      <Banner
        :pic="bannerimg"
        :title="bannertitle"
        :content="bannercontent"
      ></Banner>
      <div class="mbbutton" @click="toseemore">点击查看更多岗位</div>
      <div class="mbtitle">招聘岗位</div>
      <div class="swiper-container" :class="[{ top: gettop }]">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in requireposition.content"
            :key="index"
            @click="choosethis(item)"
            :style="{ color: choose == item.status ? '#454556' : '#9898A2' }"
          >
            <div class="mbjobcontent">
              {{ item.jobname }}
              <div class="mbline" v-if="choose == item.status"></div>
            </div>
          </div>
        </div>
      </div>
      <div style="height: 63px" v-show="gettop"></div>
      <div v-for="(item, index) in requireposition.content" :key="index">
        <div v-if="choose == item.status">
          <div class="mbduty-title">岗位职责</div>
          <div
            v-for="(item1, index1) in item.duty"
            :key="index1"
            class="mbduty-content"
            :style="{
              marginBottom: item.duty.length - 1 == index1 ? '1.25rem' : '0',
            }"
          >
            {{ item1 }}
          </div>
          <div class="mbduty-title">岗位要求</div>
          <div
            v-for="(item2, index2) in item.require"
            :key="index2 + 10"
            class="mbduty-content"
            :style="{
              marginBottom: item.require.length - 1 == index2 ? '1.25rem' : '0',
            }"
          >
            {{ item2 }}
          </div>
          <div class="mbapply" @click="toseemore">申请岗位</div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper";
import Nav from "@/components/Nav.vue";
import Footer from "@/components/Footer.vue";
import Banner from "@/components/Banner.vue";
export default {
  components: {
    Nav,
    Footer,
    Banner,
  },
  data() {
    return {
      pc: null,
      gettop: false,
      swiper: "",
      choose: "AM",
      bannertitle: "加入不凡团队，成就不凡人生",
      bannerimg: "//bosdev.liangyihui.net/temp/1618975461.png",
      bannercontent: "投递简历，请联系 hr@liangyihui.net",
      requireposition: {
        title: "招聘岗位",
        content: [
          // {
          //   jobname: "客户主任（AE）/高级客户主任",
          //   duty: [
          //     " 1.负责医药企业合作项目落地工作的对接，与客户保持良好的沟通；",
          //     "2.项目落地过程中客户需求的对接，确保需求传递的准确性及可执行性；",
          //     "3.对项目落地流程和进度进行把控；",
          //     "4. 能够合理管理客户预期；",
          //     "5. 项目总结报告、回款结算等事项跟进；",
          //   ],
          //   require: [
          //     "1.全日制统招本科及以上学历，2年及以上广告公司、医疗服务类公司经验或药企销售代表相关经验；",
          //     "2.项目管理能力：擅长项目管理，具有良好的全流程管理思维，具有丰富的项目管理相关经验；",
          //     "3.自驱力，可以快速学习医药产品相关知识；",
          //     "4.可靠与跟进客户的能力、优秀的沟通和问题解决能力；",
          //     "5.保持好奇心，主动迎接挑战，从不同角度思考解决问题；",
          //     "6.对肿瘤行业感兴趣，且希望在行业中深度发展，与公司共同成长；",
          //     "7.抗压性强，能够接受较高强度的工作；",
          //     "8.具有肿瘤领域、数字化项目相关工作经验优先考虑。",
          //   ],
          //   status: "AE",
          // },
          {
            jobname: "客户经理",
            duty: [
              "1.开拓医药企业的项目合作，为客户提供解决方案；",
              "2.带领团队推进项目的落地，为项目结果负责；",
              "3.针对大客户进行管理和维护；",
            ],
            require: [
              "1.全日制统招本科及以上学历，5年及以上医药销售经验或与医药公司数字化/线上项目的合作经验；",
              "2.团队管理经验：具有1年以上团队管理的经验和一定的团队管理思路；",
              "3.有较强的自驱力，可以快速学习医药产品、肿瘤行业、市场营销等相关知识；",
              "4.保持好奇心，不断的自主扩展认知边界，主动迎接挑战，创造性地解决问题；",
              "5.对肿瘤行业感兴趣，且希望在行业中深度发展，与公司共同发展；",
              "6.抗压性强，能够接受较高强度的工作和新的业务挑战；",
              "7.具有肿瘤领域相关项目合作经验优先考虑。",
            ],
            status: "AM",
          },
          {
            jobname: "医学编辑",
            duty: [
              "1. 负责公司产品的医学栏目，医学内容的编辑策划撰写；",
              "2. 支持产品部门和市场部门，参与医学产品的设计和开发；",
              "3. 对客户进行医学需求分析；",
              "4. 对内外部客户提供医学支持和服务；",
            ],
            require: [
              "1.全日制统招本科及以上，硕士优先，生物医学、临床医学、中医药等相关专业；",
              "2.能熟练掌握外文文献检索的方法；",
              "3.熟悉临床试验过程，能够理解临床研究数据；",
              "4.有良好的沟通表达能力、学习能力、理解能力及团队合作精神，对医学信息有浓厚的兴趣，肯钻研；",
              "5.有较高的工作效率，能合理的做时间管理，可适应加班，抗压性强；",
              "6.有临床经验者优先；有执业医师资格证者优先；有医学编辑从业经验者优先；",
              "7.肿瘤领域优先，英文好优先。",
            ],
            status: "Medicaleditor",
          },
          {
            jobname: "大客户经理",
            duty: [
              "1.结合公司产品策划符合医院、科室、专家需求的项目，并独立完成策划、创意、管理；",
              "2.维护重要客户资源，并挖掘与客户长期深度合作的商业机会；",
              "3.根据部门业务方向开拓潜在客户，促进生意模式的建立；",
              "4.根据部门的目标建立小组人员的发展和管理计划；",
              "5.协助部门经理制定部门客户服务、发展的目标和策略；以及监督、指导客服团队按照公司规定的流程和标准向客户提供服务，完成经营目标；",
            ],
            require: [
              "1.5年以上广告公关公司客户服务，医药企业市场销售部岗位经验，肿瘤药物优先；",
              "2.大专以上学历，娴熟的沟通交际能力；",
              "3.善于客户关系维护和建立，有市场开拓能力；",
              "4.独立项目管理或团队管理经验；",
              "5.亲和力和沟通能力好，工作认真细致，办事有条理，抗压和适应性强。",
            ],
            status: "KAM",
          },
          // {
          //   jobname: "科研服务专员/经理",
          //   duty: [
          //     "1.独立管理医生科研、药企RWS项目，主要包括临床协调、需求沟通；",
          //     "2.统筹医生科研项目的客户需求管理；",
          //     "3.基于项目需求，协助数字平台产品工作；",
          //     "4.完成上级交办的其他任务并及时反馈；",
          //   ],
          //   require: [
          //     "1.医学、生命科学等相关专业，本科及以上学历；",
          //     "2.3年以上医生科研或临床研究项目经验，有CRC或CRA经验者优先；",
          //     "3.有临床研究相关数据产品、平台产品的实操经验优先；",
          //     "4.极强的沟通协调能力，有亲和力；",
          //     "5.严谨科学的职业素养。",
          //   ],
          //   status: "RSS",
          // },
          // {
          //   jobname: "市场专员",
          //   duty: [
          //     "1、结合公司现有业务和资源，对业务数据进行分析，并协助制定公司市场策略；",
          //     "2、 深度参与市场调研、竞争对手分析，为企业提供准确的、系统的、及时的市场信息，并对各种信息进行综合分析，协助撰写相关市场调研报告，为公司决策提供依据；",
          //     "3、 能够深度参与业务，内外部推动执行力强；",
          //     "4、 协助制定公司中长期市场战略规划以及年度计划；",
          //     "5、 部门领导安排的其他工作。",
          //   ],
          //   require: [
          //     "1、 全日制硕士学历优先，特别优秀者可适当放宽至本科，医药生物相关专业/市场营销管理专业/MBA；",
          //     "2、 1年以上医药战略咨询、市场营销、肿瘤药企、互联网医疗平台、数字化营销相关经验；",
          //     "3、 EXCEL、PPT等办公软件使用熟练；",
          //     "4、 英文沟通能力流利。",
          //   ],
          //   status: "MS",
          // },
          // {
          //   jobname: "患者运营专员/主管",
          //   duty: [
          //     "1、负责日常的在线患者的客服工作，包括但不限于患教资讯发布，寻医问药解答，问诊会诊接待等；",
          //     "2、及时收集在客服（患者服务）过程中发现的问题反馈给部门主管，优化团队的患者服务流程；",
          //     "3、负责肿瘤患者微信社群等的日常运营，通过有计划和有目的的运营活动和内容输出与患者互动，促进患者口碑传播，提升粘度；",
          //     "4、部门其它患者服务项目（包括但不限于患教直播、在线义诊等）的运营支持；",
          //     "5、上级安排的其它工作事项。",
          //   ],
          //   require: [
          //     "1，大专及以上学历，1年以上客户线上运营相关工作经验；",
          //     "2，有医学/护理学/心理学/教育学背景者优先，有客服工作经验者优先；",
          //     "3，性格开朗，有耐心，工作积极主动，能积极帮助患者解决问题；具有自驱力及吃苦耐劳精神；",
          //     "4，具有良好的沟通能力和一定的抗压能力，具有创新和团队合作精神；",
          //     "5，能熟练运用Microsoft Office等办公软件。",
          //   ],
          //   status: "POS",
          // },
          // {
          //   jobname: "患者中心负责人",
          //   duty: [
          //     "1.负责本区域内合作医院患者服务中心的筹建、日常运营，为肿瘤医院、医生、患者提供专业高效的服务；",
          //     "2.与合作医院、医生保持紧密的合作关系，及时发现合作伙伴的患者服务需求，提出可行的解决方案，并推动执行；",
          //     "3.负责本区域内其它患者服务项目的开拓和运营；",
          //     "4.为本区域内的患者上线数量负责；",
          //     "5.公司其他部门患者项目（包括不限于患教等）的支持，以及其他可能需要的工作配合。",
          //   ],
          //   require: [
          //     "1.本科以上学历，3年及以上工作经验，医学背景优先，医疗行业工作经验优先，有患者服务类项目经验优先；",
          //     "2.主观能动性强，执行力强，拿结果能力强；",
          //     "3.擅长沟通协调，有创造性的解决问题的能力；",
          //     "4.诚实正直，心态开放，持续学习。",
          //   ],
          //   status: "PIP",
          // },
          {
            jobname: "医生运营经理",
            duty: [
              "1.负责用户体系的建设和管理，促进用户留存和价值输出；",
              "2.负责规划目标医生用户群体运营策略，制定医生社群的运营规则，对社群用户的数量与质量负责；",
              "3.负责医生学术活动（线上/线下）的策划、宣传推广、活动执行，并进行效果分析，实现活动运营目标；",
              "4.维护平台活跃用户、激活沉默用户，针对不同用户类型设计有效激励机制，并对新增、留存、活跃等关键指标负责；",
              "5.与专家、KOL协作，策划、输出医疗领域的相关内容，并持续提供方案支持，扩大医生品牌影响力；",
              "6.通过对平台数据及用户反馈信息的分析、处理，优化、提升用户运营效率。",
            ],
            require: [
              "1.全日制本科以上学历，互联网行业3年以上用户运营策划经验，医疗行业或者医疗相关项目工作经验优先；",
              "2.对数据敏感，有独立完整的数据分析经验，能快速发掘业务和数据的联系；",
              "3.具有良好的表达能力、沟通协调能力，执行力强；",
              "4.能够吃苦耐劳，适应高强度的工作节奏，有责任心和团队合作精神。",
            ],
            status: "DOM",
          },
          {
            jobname: "新媒体运营",
            duty: [
              "1. 负责搭建大众癌症科普全媒体矩阵及全媒体账号定位、策划落地；",
              "2. 具备优秀的数据分析能力，负责日常数据流量分析并根据数据制定相关用户增长策略、内容运营和活动运营计划，完成内容阅读量、粉丝量及日常运维要求；",
              "3. 熟悉自媒体、新媒体内容策划，可独立进行内容创意策划，文章撰写；",
              "4. 负责相关社群运营及产品转化；",
              "5. 完成上级安排的其它工作；",
            ],
            require: [
              "1.大专及以上学历，3-5年以上行业经验，有快消、医疗互联网公司新媒体运营经验者优先",
              "2.具有较强的文字功底和表现能力、表达及沟通协调能力",
              "3.具有敏锐的市场感知及分析能力，能够迅速把握市场动态和市场发展方向",
              "4.熟悉主流社交软件操作及运营，特别是短视频策划及制作",
              " 5.熟悉用户生命周期不同节点增长的特征、方法、手段、有较强的获客能力",
              " 6.诚信正直，积极主动，有良好的情绪控制能力及抗压能力",
              "7.有电商、MCN机构经验者优先",
            ],
            status: "NMM",
          },
          // {
          //   jobname: "Java开发工程师",
          //   duty: [
          //     "1.参与公司产品和项目的后台开发工作；",
          //     "2.参与产品需求讨论和评审，理解产品需求，并能积极给出意见；",
          //     " 3.参与或主导技术架构设计或技术解决方案提供；",
          //     "4.团队中积极分享，并能指导初中级开发工程师；",
          //     "5.技术支持和协作；",
          //   ],
          //   require: [
          //     "1.计算机相关专业大学本科及以上学历，知名重点大学优先，5年以上工作经验，特别优秀的可以适当放宽；",
          //     "2.开发语言和技术路线以Java为主的，有扎实的Java基础，熟悉主流的Spring+Mybatis等开发框架；",
          //     "3.有1年以上的技术架构经验，熟悉SOA和微服务、分布式架构和高并发设计的优先考虑；",
          //     "4.熟悉常用的数据库，比如：Oracle、MySQL等；熟悉OLAP、数据仓库和大数据技术的优先考虑；",
          //     "工作积极主动，能够快速学习业务需要的知识，并能在工作中不断成长；",
          //     "5.具备良好的沟通能力、分析和解决问题的能力；",
          //     "6.为人踏实稳重，有创业精神并能承受一定的工作压力，团队合作能力较强。",
          //   ],
          //   status: "JAVA",
          // },
        ],
      },
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.pc = this.IsPC();
    if (this.pc == "mobil") {
      this.bannerimg = "//bosdev.liangyihui.net/temp/1618903801.png";
    }
  },
  mounted() {
    this.pc = this.IsPC();
    sessionStorage.removeItem("nav");
    if (this.pc == "mobil") {
      this.bannerimg = "//bosdev.liangyihui.net/temp/1618903801.png";
    }
    this.swiper = new Swiper(".swiper-container", {
      slidesPerView: "auto",
      observer: true, //修改swiper自己或子元素时，自动初始化swiper
      observeParents: true, //修改swiper的父元素时，自动初始化swiper
    });
    if (this.pc == "mobil") {
      this.$nextTick(() => {
        this.settabmbtop();
      });
    }
  },
  methods: {
    settabmbtop() {
      setTimeout(() => {
        let searcheight = 0;
        searcheight =
          document.getElementsByClassName("swiper-container")[0].offsetTop - 60;
        // console.log(document.getElementsByClassName('tag')[0])
        window.addEventListener("scroll", () => {
          if (window.pageYOffset - searcheight > 0) {
            this.gettop = true;
          } else {
            this.gettop = false;
          }
        });
      }, 800);
    },
    IsPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = new Array(
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod"
      );
      var flag = "pc";
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = "mobil";
          break;
        }
      }
      return flag;
    },
    jumpMenu(className) {
      if (window.location.pathname != className.item.url) {
        this.$router.push(className.item.url);
        return;
      }
    },
    choosethis(item) {
      this.choose = item.status;
    },
    toseemore() {
      window.location.href =
        "https://www.zhipin.com/gongsir/1e38d899a58654fe1nd83d-6.html?ka=company-jobs";
    },
  },
};
</script>
<style lang="less" scoped>
@import "../assets/px.less";
@import "../../node_modules/swiper/swiper.min.css";
.Joinus {
  min-height: calc(100vh - @px60);
  background-color: #fafafa;
  text-align: left;
}
.recruitbox {
  text-align: left;
  margin: @px40 auto @px40;
  width: @px1200;
  overflow: hidden;
  &-left {
    color: #373747;
    float: left;
    width: @px400;
    background-color: #fff;
    &-title {
      height: @px120;
      box-sizing: border-box;
      padding: @px40 0 @px40 @px24;
      font-size: @px32;
      font-weight: bold;
      line-height: @px40;
      margin-bottom: @px16;
    }
    &-item {
      cursor: pointer;
      border-radius: @px4;
      height: @px72;
      background-color: #fafafa;
      margin: 0 @px24 @px16 @px24;
    }
    &-itembox {
      height: 100%;
      width: 100%;
      box-sizing: border-box;
      padding: @px21 @px16 @px23 @px16;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-itemnormal {
      height: @px88;
      padding: 0 @px40;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
    }
    &-button {
      cursor: pointer;
      font-weight: bold;
      margin: @px24;
      height: @px64;
      width: @px352;
      border-radius: @px4;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: @px20;
      line-height: @px28;
      color: #fff;
      background-color: #cd0070;
    }
  }
  &-right {
    float: right;
    box-sizing: border-box;
    padding: @px40 @px24 @px24;
    margin-bottom: @px40;
    margin-left: @px20;
    width: @px780;
    min-height: @px672;
    background-color: #fff;
    color: #454556;
    &-topbox {
      display: flex;
      justify-content: space-between;
      align-content: center;
    }
    &-job {
      font-size: @px24;
      line-height: @px38;
      font-weight: bold;
    }
    &-button {
      cursor: pointer;
      border-radius: @px4;
      border: @px1 solid #cd0070;
      color: #454556;
      font-size: @px16;
      line-height: @px22;
      color: #cd0070;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: @px8 @px16;
    }
  }
  .line {
    margin: @px24 0 @px40 0;
    width: 100%;
    height: @px1;
    background-color: #e5e5e8;
  }
  .requirebox {
    color: #454556;
    font-size: @px20;
    line-height: @px28;
    font-weight: bold;
    display: flex;
    align-items: center;
    &-line {
      width: @px4;
      height: @px18;
      background-color: #cd0070;
      margin-right: @px10;
    }
  }
  .requirecontent {
    line-height: @px24;
    color: #6f6f7a;
    font-size: @px16;
    margin: @px16 0px @px40 0px;
  }
  .position {
    font-weight: bold;
    font-size: @px20;
    line-height: @px28;
  }
  .icon {
    padding-top: @px30;
  }
  .positiontop {
    font-weight: bold;
    font-size: @px20;
    line-height: @px28;
    color: #cd0070;
  }

  .iconimg {
    width: @px12;
    height: @px12;
  }
}
.mbJoinus {
  text-align: left;
}
.mbbutton {
  border-radius: @px4;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: @px20 auto @px40;
  background-color: #cd0070;
  color: #fff;
  width: @px144;
  height: @px40;
  line-height: @px40;
  font-size: @px14;
  font-weight: bold;
}
.mbtitle {
  margin-bottom: @px8;
  text-align: center;
  color: #454556;
  font-size: @px18;
  line-height: @px26;
  font-weight: bold;
}
.swiper-container {
  padding: @px11 @px20 @px10;
  border-bottom: @px1 solid #e5e5e8;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  margin-bottom: @px19;
}

.swiper-slide {
  width: auto;
  margin-right: @px20;
  color: #9898a2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mbline {
  width: @px30;
  height: @px2;
  background-color: #cd0070;
  border-radius: @px1;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -0.688rem;
}
.mbduty-title {
  margin: 0 0 @px16 @px20;
  font-size: @px18;
  line-height: @px26;
  font-weight: bold;
  color: #454556;
}
.mbduty-content {
  color: #6f6f7a;
  font-size: @px14;
  line-height: @px20;
  padding: 0 @px20;
  width: 100%;
  box-sizing: border-box;
}
.mbapply {
  width: @px86;
  border-radius: @px4;
  border: @px1 solid #cd0070;
  color: #cd0070;
  font-weight: bold;
  font-size: @px14;
  line-height: @px40;
  height: @px38;
  text-align: center;
  margin: 0 0 @px40 @px20;
}
.mbjobcontent {
  font-weight: bold;
  display: inline-block;
  position: relative;
}
.top {
  top: @px60;
  position: fixed;
  z-index: 100;
  width: 100%;
  background-color: #fff;
}
</style>
