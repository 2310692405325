const devConfig = require("./dev.config");
const prodConfig = require("./prod.config");

// //console.log(devConfig);
// //console.log(prodConfig);

const env = process.env.NODE_ENV;

const config = {
  env,
};

if (env === "development") {
  console.log(111);
  Object.assign(config, config, devConfig);
} else if (env === "production") {
  console.log(222);
  Object.assign(config, config, prodConfig);
}
//console.log(config);
// export default config;
module.exports = config;
