const dev_config = {
  PUBLIC_PATH: "/surgerydemo",
  APISERVER_URL: "//demoapi.liangyihui.net/",
  // APISERVER_URL: "//192.168.5.27:8080/",
  NODESERVER_URL: "http://demo.liangyihui.net/",
  LOCAL_URl: "http://cdnmini.liangyihui.net/surgerydemo/#/",
};

// export default dev_config;
module.exports = dev_config;
