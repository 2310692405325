import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Joinus from "../views/Joinus.vue";
import Responsibility from "../views/Responsibility.vue";
import NewMedia from "../views/NewMedia.vue";
import Business from "../views/Business.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title:
        "良医汇-围绕医生和患者 数字化赋能服务企业 打造垂直肿瘤领域的生态平台",
      shorttitle: "良医汇",
    },
  },
  {
    path: "/joinus/",
    name: "Joinus",
    meta: {
      title: "加入我们-加入不凡团队，成就不凡人生",
      shorttitle: "加入我们",
    },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Joinus,
  },
  {
    path: "/responsibility/",
    name: "Responsibility",
    meta: {
      title: "企业责任-更好地服务肿瘤患者",
      shorttitle: "企业责任",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Responsibility,
  },
  {
    path: "/newMedia/",
    name: "NewMedia",
    meta: {
      title: "新媒体-影响力领先的医学新媒体",
      shorttitle: "新媒体",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: NewMedia,
  },
  {
    path: "/Business/",
    name: "Business",
    meta: {
      title: "业务-肿瘤垂直领域数字化创新服务企业",
      shorttitle: "业务",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Business,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
