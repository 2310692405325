import wx from "weixin-js-sdk";
import axios from "axios";
export const jssdk = (callback) => {
  window._wx = wx;
  console.log(callback);
  setTimeout(function () {
    axios
      .post("https://doctor.liangyihui.net/doctor/tp/Index/jsk", {
        url: location.href.split("#")[0],
      })
      .then((res) => {
        // console.log('123123123123123123')
        // console.log(res);
        if (res.status == 200) {
          wx.config({
            debug: false,
            appId: res.data.appId,
            timestamp: res.data.timestamp * 1,
            nonceStr: res.data.nonceStr,
            signature: res.data.signature,
            jsApiList: [
              "chooseImage",
              "previewImage",
              "onMenuShareTimeline",
              "onMenuShareAppMessage",
              "onMenuShareQQ",
              "onMenuShareWeibo",
              "onMenuShareQZone",
            ],
            openTagList: ["wx-open-launch-weapp"],
          });
          var shareJson;
          wx.ready(function () {
            switch (location.pathname) {
              case "/":
                shareJson = {
                  title: "良医汇-围绕医生和患者，数字化赋能服务企业，打造垂直肿瘤领域的生态平台", // 分享标题
                  desc: "赋能肿瘤医生，服务肿瘤患者，助力产业升级。", // 分享描述
                  link: location.href, // 分享链接
                  imgUrl: "http://bosdev.liangyihui.net/temp/1619169085.png", // 分享图标
                };
                break;
              case "/Business/":
                shareJson = {
                  title: "良医汇-业务", // 分享标题
                  desc:
                    "肿瘤垂直领域数字化创新服务企业，致力于打造中国最具影响力、最大规模的肿瘤一站式医患企数据化服务生态。", // 分享描述
                  link: location.href, // 分享链接
                  imgUrl: "http://bosdev.liangyihui.net/temp/1619169085.png", // 分享图标
                };
                break;
              case "/NewMedia/":
                shareJson = {
                  title: "良医汇-新媒体", // 分享标题
                  desc:
                    "影响力领先的医学新媒体，分享肿瘤领域最新进展，传播正确肿瘤防治理念，提高全社会对肿瘤的关注", // 分享描述
                  link: location.href, // 分享链接
                  imgUrl: "http://bosdev.liangyihui.net/temp/1619169085.png", // 分享图标
                };
                break;
              case "/Responsibility/":
                shareJson = {
                  title: "良医汇-企业责任", // 分享标题
                  desc:
                    "更好地服务肿瘤患者，改善医疗资源分布不均衡，降低患者治疗费用，延长患者长期生存。", // 分享描述
                  link: location.href, // 分享链接
                  imgUrl: "http://bosdev.liangyihui.net/temp/1619169085.png", // 分享图标
                };
                break;
              case "/Joinus/":
                shareJson = {
                  title: "良医汇-加入我们", // 分享标题
                  desc:
                    "加入不凡团队，成就不凡人生，投递简历，请联系 hr@liangyihui.net。", // 分享描述
                  link: location.href, // 分享链接
                  imgUrl: "http://bosdev.liangyihui.net/temp/1619169085.png", // 分享图标
                };
                break;
              default:
                shareJson = {
                  title: "", // 分享标题
                  desc: "", // 分享描述
                  link: location.href, // 分享链接
                  imgUrl: "http://bosdev.liangyihui.net/temp/1619169085.png", // 分享图标
                };
            }

            // 分享给朋友
            wx.onMenuShareAppMessage({
              title: shareJson.title,
              desc: shareJson.desc,
              link: shareJson.link,
              imgUrl: shareJson.imgUrl,
              success: function () {
                // 用户确认分享后执行的回调函数
              },
              cancel: function () {
                // 用户取消分享后执行的回调函数
              },
            });
            // 分享到朋友圈
            wx.onMenuShareTimeline({
              title: shareJson.title, // 分享标题
              link: shareJson.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: shareJson.imgUrl, // 分享图标
              success: function () {
                // 用户确认分享后执行的回调函数
              },
              cancel: function () {
                // 用户取消分享后执行的回调函数
              },
            });
            // 分享到QQ
            wx.onMenuShareQQ({
              title: shareJson.title, // 分享标题
              desc: shareJson.desc,
              link: shareJson.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: shareJson.imgUrl, // 分享图标
              success: function () {
                // 用户确认分享后执行的回调函数
              },
              cancel: function () {
                // 用户取消分享后执行的回调函数
              },
            });
            // 分享到腾讯微博
            wx.onMenuShareWeibo({
              title: shareJson.title, // 分享标题
              desc: shareJson.desc, // 分享描述
              link: shareJson.link, // 分享链接
              imgUrl: shareJson.imgUrl, // 分享图标
              success: function () {
                // 用户确认分享后执行的回调函数
              },
              cancel: function () {
                // 用户取消分享后执行的回调函数
              },
            });
            // 分享到QQ空间
            wx.onMenuShareQZone({
              title: shareJson.title, // 分享标题
              desc: shareJson.desc, // 分享描述
              link: shareJson.link, // 分享链接
              imgUrl: shareJson.imgUrl, // 分享图标
              success: function () {
                // 用户确认分享后执行的回调函数
              },
              cancel: function () {
                // 用户取消分享后执行的回调函数
              },
            });
          });
        } else {
          alert(res.msg);
        }
      });
  }, 25);
};
