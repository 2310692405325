<template>
  <div>
    <!-- pc -->
    <div class="home" v-if="pc == 'pc'">
      <Nav titlename="introduction" @jumpMenu="jumpMenu"></Nav>
      <div class="bgbox">
        <div class="bg">
          <div class="banner">
            <img
              src="//bosdev.liangyihui.net/temp/1619175264.png"
              alt=""
              class="banner-img"
            />
            <img
              class="banner-font"
              src="//bosdev.liangyihui.net/temp/1619175217.png"
              alt=""
            />
          </div>
        </div>
        <!-- <div class="tovideo">
          <img
            src="//bosdev.liangyihui.net/temp/1617002496.png"
            alt=""
            class="tovideo-img"
          />
          <div class="tovideo-font">播放完整视频</div>
        </div> -->
      </div>
      <div class="introduce">
        <div class="maintitle">{{ introduceMsg.maintitle }}</div>
        <div class="title">{{ introduceMsg.title }}</div>
        <div class="message">{{ introduceMsg.message }}</div>
      </div>
      <div class="parttitle mv">使命及愿景</div>
      <div class="mvbox">
        <div class="mvbox-content">
          <div
            class="mvbox-content-style"
            v-for="(item, index) in mv"
            :key="index"
          >
            <img :src="item.pic" alt="" class="icon" />
            <div>
              <div class="mvtitle">{{ item.title }}</div>
              <div class="mvdetail">{{ item.detail }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="parttitle culturetitle">企业文化</div>
      <div class="culture">
        <div
          v-for="(item, index) in culture"
          :key="index"
          class="culture-box"
          :style="{
            backgroundImage: 'url(' + item.bg + ')',
          }"
        >
          <div>
            <div class="culture-box-title">{{ item.title }}</div>
            <div class="culture-box-content">{{ item.content }}</div>
          </div>
        </div>
      </div>
      <div class="eventspart">
        <div class="eventspart-box">
          <div class="eventspart-title">发展大事记</div>
          <div class="rprogress">
            <div
              v-for="(item, index) in time"
              :key="index"
              :style="{
                color:
                  item.status == choosetime
                    ? 'rgba(255, 255, 255, 1)'
                    : 'rgba(255, 255, 255, 0.5)',
              }"
              class="point"
              @click="changetime(item.status, index)"
            >
              {{ item.title }}
            </div>
          </div>
          <div class="rprogress">
            <div v-for="(item, index) in time" :key="index" class="ballbox">
              <div class="ball">
                <div
                  class="ballcontent"
                  :style="{
                    backgroundColor:
                      item.status == choosetime ? 'rgba(255, 255, 255, 1)' : '',
                  }"
                  @click="changetime(item.status, index)"
                ></div>
              </div>
              <div class="line" v-if="index != time.length - 1"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="substance">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in time1"
              :key="index"
            >
              <div class="timetitle">{{ item.title }}</div>
              <div class="line1"></div>
              <div
                v-for="(item1, index1) in item.bigevent"
                :key="index1"
                class="eventbox"
              >
                <div class="timebox">{{ item1.time }}</div>
                <div class="contentboxhome">{{ item1.content }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="partners">
        <div class="eventspart-title">合作伙伴</div>
        <div class="partners-imgbox">
          <img
            src="//rs-os-lyh-dt-publicread-picture-bosmetadata-test.liangyihui.net/document_N/JTsT-HvJU3WNt28iFH2q070q3moLVd3C2SeuLyJmyxJ8G0afM1sQtyfpRhu-DqVX/gCJOpz6854mVjfOv8xGrgjThFsKMyxPn.png"
            alt=""
            class="partners-imgbox-img"
          />
        </div>
        <div class="partners-footer">*合作企业按企业英文名称首字母排序</div>
      </div>
      <Footer></Footer>
    </div>
    <!-- 移动端 -->
    <div class="mbhome" v-if="pc == 'mobil'">
      <Nav titlename="introduction"></Nav>
      <div class="mbbanner">
        <img
          src="//bosdev.liangyihui.net/temp/1617850210.png"
          alt=""
          class="mbbanner-img"
        />
      </div>
      <div class="mbmaintitle">{{ introduceMsg.maintitle }}</div>
      <div class="mbintroduce">
        <div class="mbtitle">{{ introduceMsg.title }}</div>
        <div class="mbmessage">{{ introduceMsg.message }}</div>
      </div>
      <div class="mbparttitle">使命及愿景</div>
      <div class="mbmvbox">
        <div class="mbmvbox-content">
          <div
            class="mbmvbox-content-style"
            v-for="(item, index) in mv"
            :key="index"
            :style="{ marginTop: mv.length - 1 == index ? '1rem' : '' }"
          >
            <img :src="item.pic" alt="" class="mbicon" />
            <div class="mbbox">
              <div class="mbmvtitle">{{ item.title }}</div>
              <div class="mbmvdetail">{{ item.detail }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="mbparttitle">企业文化</div>
      <div class="mbculture">
        <div
          v-for="(item, index) in culture"
          :key="index"
          class="mbculture-box"
          :style="{
            backgroundImage: 'url(' + item.bg + ')',
          }"
        >
          <div>
            <div class="mbculture-box-title">{{ item.title }}</div>
            <div class="mbculture-box-content">{{ item.content }}</div>
          </div>
        </div>
      </div>
      <div class="mbparttitle" style="marginBottom: 8px">发展大事纪</div>

      <div class="mbrprogress">
        <div
          v-for="(item, index) in time"
          :key="index"
          :style="{
            color: item.status == choosetime ? '#454556' : '#9898A2',
          }"
          @click="changetime(item.status, index)"
          class="mbrprogress-box"
        >
          {{ item.title }}
          <div class="mbline" v-if="item.status == choosetime"></div>
        </div>
      </div>

      <div v-for="(item, index) in time1" :key="index">
        <div v-if="item.status == choosetime" class="mbeventbox">
          <div
            v-for="(item1, index1) in item.bigevent"
            :key="index1"
            class="mbselectbox"
            :style="{
              marginBottom: item.bigevent.length - 1 == index1 ? '0' : '1rem',
            }"
          >
            <div class="mbtimebox">{{ item1.time }}</div>
            <div class="mbcontentboxhome">{{ item1.content }}</div>
          </div>
        </div>
      </div>
      <div class="mbparttitle">合作伙伴</div>
      <div class="mbpartners">
        <img src="//rs-os-lyh-dt-publicread-picture-bosmetadata-test.liangyihui.net/document_N/JTsT-HvJU3WNt28iFH2q070q3moLVd3C2SeuLyJmyxJ8G0afM1sQtyfpRhu-DqVX/WXYLYt4iJ85dEyglkLy2hASVh0HR8s60.png" alt="" />
      </div>
      <div class="mbtap">*合作企业按企业英文名称首字母排序</div>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Nav from '@/components/Nav.vue'
import Swiper from 'swiper'
import Footer from '@/components/Footer.vue'
export default {
  name: 'Home',
  data() {
    return {
      pc: null, //是否是pc
      swiper: '',
      choosetime: 'now',
      introduceMsg: {
        maintitle: '良医汇简介',
        title: '肿瘤垂直领域专业教育和医患企服务平台',
        message:
          '苏州良医汇网络科技公司（以下简称【良医汇】）成立于2015年3月，是一家专注于肿瘤垂直领域的创新型互联网、数字科技医疗公司。我们秉承“让肿瘤的诊疗更加高效和节省”的使命，通过旗下各类平台，帮助医生提高临床诊疗的规范性和临床诊治水平；为患者提供权威的疾病诊疗、寻医问药资讯和服务；为企业提供更高效的客户教育和服务解决方案。',
      },
      mv: [
        {
          title: '使命',
          pic: '//bosdev.liangyihui.net/temp/1617353113.png',
          detail: '让肿瘤的诊疗更加高效和节省',
        },
        {
          title: '愿景',
          pic: '//bosdev.liangyihui.net/temp/1617353344.png',
          detail: '国内最大的肿瘤医疗数字化创新平台',
        },
      ],
      culture: [
        {
          title: '专业',
          content: '理性思考  有效沟通',
          bg: '//bosdev.liangyihui.net/temp/1617354731.png',
        },
        {
          title: '诚信',
          content: '言行坦荡  说到做到',
          bg: '//bosdev.liangyihui.net/temp/1617354735.png',
        },
        {
          title: '创新',
          content: '不断学习  超越预期',
          bg: '//bosdev.liangyihui.net/temp/1617354739.png',
        },
        {
          title: '高效',
          content: '精诚协作  结果导向',
          bg: '//bosdev.liangyihui.net/temp/1617354860.png',
        },
      ],
      time: [
          { title: '现在', status: 'now', bigevent: '' },
          { title: '2023', status: '2023', bigevent: '' },
          { title: '2022', status: '2022', bigevent: '' },
        {
          title: '2021',
          status: '2021',
          bigevent: [
            { time: '2021', content: '良医汇完成超亿元C轮融资' },
            { time: '2021', content: '数据管家' },
          ],
        },
        { title: '2020', status: '2020', bigevent: '' },
        { title: '2018', status: '2018', bigevent: '' },
        { title: '2016', status: '2016', bigevent: '' },
        { title: '2015', status: '2015', bigevent: '' },
      ],
      time1: [
        {
          title:'现在',
          status:'now',
          bigevent:[
            {
              time:'2024',
              content:'认定江苏省潜在独角兽企业'
            },
            {
              time:'2024',
              content:'认定江苏省民营科技企业'
            },
            {
              time:'2024',
              content:'获评未来医疗100强.医疗健康产业链服务榜TOP100'
            },
            {
              time:'2024',
              content:'年度创新成长奖'
            },
            {
              time:'2024',
              content:'获得《互联网药品信息服务资格证书》'
            }
         ]
        },
         {
          title:'2023',
          status:'2023',
          bigevent:[
           {
               time:'2023',
              content:'认定苏州市工业园区上市苗圃重点企业'
          },
           {
               time:'2023',
              content:'获评投中榜·锐公司100榜单'
          },
             {
               time:'2023',
              content:'胡润全球猎豹企业榜'
          },
             {
               time:'2023',
              content:'未来医疗100强·中国创新医疗服务榜TOP100'
          },
          {
               time:'2023',
              content:'认定苏州工业园区专精特新中小企业'
          }
         ]
        },
        {
          title: '2022',
          status: '2022',
          bigevent:[
               {
              time:'2022',
              content:'认定苏州工业园区综合性总部企业'
            },
            {
               time:'2022',
              content:'认定江苏省潜在独角兽企业'
          },
           {
               time:'2022',
              content:'认定江苏省瞪羚企业'
          },
           {
               time:'2022',
              content:'认定江苏省良医汇互联网医院平台工程技术研究中心'
          },
           {
               time:'2022',
              content:'获评最具创新性医疗科技产品（解决方案）TOP100'
          },
             {
               time:'2022',
              content:'获评第二届中欧校友公益评选活动-践行奖'
          },
             {
               time:'2022',
              content:'苏州市人工智能行业协会会员单位'
          },
          {
               time:'2022',
              content:'获评未来医疗100强，创新医疗服务榜TOP100'
          }
          ]
        },
        {
          title: '2021',
          status: '2021',
          bigevent: [
            {
              time:'2021',
              content:'获评中国医疗健康服务产业最佳医疗服务领域投资TOP10'
            },
             {
              time: '2021',
              content: '获评江苏省高新区潜在独角兽企业',
            },
            {
              time: '2021',
              content: '完成数亿C+轮融资',
            },
            {
              time: '2021',
              content: '全球人工智能大会获评年度最具发展潜力企业',
            },
            {
              time: '2021',
              content: '认定江苏省民营科技企业',
            },
            { time: '2021', content: '完成超亿元C轮融资' },
            { time: '2021', content: '数据管家' },
            {
              time: '2021',
              content: '获评医疗投资卓悦榜 · 年度智慧医疗最佳企业',
            },
            {
              time: '2021',
              content: '获评未来医疗100强，创新医疗服务榜TOP100',
            },
          ],
        },
        {
          title: '2020-2019',
          status: '2020',
          bigevent: [
            { time: '2020', content: '大数据精准互动系统正式投入应用' },
            { time: '2020', content: '获得互联网医院执照' },
            { time: '2020', content: '获评苏州市独角兽培育企业' },
            { time: '2020', content: '认定苏州市企业工程技术研究中心' },

            { time: '2020', content: '获评苏州市工业园区瞪羚培育企业' },
            {
              time: '2020',
              content: '荣获中国初级卫生保健基金会官方指定联合劝募伙伴',
            },
            { time: '2020', content: '获评年度智慧医疗投资卓悦榜' },
            {
              time: '2020',
              content: '人民日报健康客户端、健康时报社战略合作伙伴单位',
            },
            { time: '2020', content: '苏州市工业园区上市苗圃企业' },
            { time: '2019', content: 'O2O患者服务中心正式运营' },
            { time: '2019', content: '未来医疗100强中国榜澎橙奖' },
            { time: '2019', content: '未来医疗100强中国榜医生学术培训TOP5' },
            { time: '2019', content: '最佳创新赋能奖' },
            { time: '2019', content: '获评中国非公立医疗机构协会会员单位' },
          ],
        },
        {
          title: '2018-2017',
          status: '2018',
          bigevent: [
            { time: '2018', content: '肿瘤大数据、患者管理的业务上线' },
            { time: '2018', content: '血液肿瘤资讯正式运营' },
            { time: '2018', content: '获评最具影响力医疗新媒体奖' },
            { time: '2018', content: '获评未来医疗100强最具潜力企业' },
            { time: '2018', content: '获评国家高新技术企业' },
            {
              time: '2017',
              content:
                '全球肿瘤防治中心正式运营，携手中山大学肿瘤防治中心共建医联体',
            },
            // {
            //   time: '2017',
            //   content: '获评中山大学附属肿瘤医院杰青摇篮奖',
            // },
          ],
        },
        {
          title: '2016',
          status: '2016',
          bigevent: [
            { time: '2016', content: '患者指南微信服务号正式运营' },
            { time: '2016', content: '获评未来医疗 100 强最具潜力企业' },
          ],
        },
        {
          title: '2015',
          status: '2015',
          bigevent: [{ time: '2015', content: '公司成立、肿瘤医生app上线' }],
        },
      ],
    }
  },
  methods: {
    jumpMenu(className) {
      if (window.location.pathname != className.item.url) {
        this.$router.push(className.item.url)
        return
      }
      let top =
        document.querySelectorAll(`.${className.className}`)[0].offsetTop - 60
      window.scrollTo(0, top)
      sessionStorage.removeItem('nav')
    },
    changetime(time, index) {
      this.choosetime = time
      if (this.pc == 'pc') {
        this.swiper.slideTo(index, 300, false)
      }
      // console.log(index);
    },
    IsPC() {
      var userAgentInfo = navigator.userAgent
      var Agents = new Array(
        'Android',
        'iPhone',
        'SymbianOS',
        'Windows Phone',
        'iPad',
        'iPod'
      )
      var flag = 'pc'
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = 'mobil'
          break
        }
      }
      return flag
    },
  },
  created() {
    this.pc = this.IsPC()
    // console.log(this.pc);
  },
  mounted() {
    this.pc = this.IsPC()
    let that = this
    if (this.pc == 'mobil') {
      sessionStorage.removeItem('nav')
    }
    let tonavJson = sessionStorage.getItem('nav')
    let tonav = null
    this.swiper = new Swiper('.swiper-container', {
      slidesPerView: 3,
      observer: true, //修改swiper自己或子元素时，自动初始化swiper
      observeParents: true, //修改swiper的父元素时，自动初始化swiper
      on: {
        slideChangeTransitionEnd: function () {
          that.choosetime = that.time[this.activeIndex].status
          // console.log(that.time[this.activeIndex]);
        },
      },
    })
    if (tonavJson) {
      tonav = JSON.parse(tonavJson)
      if (tonav.title != location.pathname) {
        window.scrollTo(0, 0)
        sessionStorage.removeItem('nav')
        return
      }
      // console.log(tonav);
      setTimeout(() => {
        this.$nextTick(() => {
          let top =
            document.querySelectorAll(`.${tonav.className}`)[0].offsetTop - 60
          window.scrollTo(0, top)
          sessionStorage.removeItem('nav')
        })
      }, 100)
    } else {
      window.scrollTo(0, 0)
    }
  },
  destroyed() {},
  components: {
    Nav,
    Footer,
  },
}
</script>
<style lang="less" scoped>
@import '../assets/px.less';
@import '../../node_modules/swiper/swiper.min.css';
.home {
  text-align: left;
  min-width: @px1200;
}
.bgbox {
  height: @px600;
  color: #fff;
  text-align: center;
}
.bg {
  position: absolute;
  width: 100%;
  min-width: @px1200;
  overflow-x: hidden;
  height: @px600;
  top: 0;
}
.banner {
  width: @px1200;
  height: @px540;
  margin: @px60 auto 0;
  position: relative;
  &-font {
    position: absolute;
    left: 0;
    top: @px140;
    width: @px880;
    height: @px220;
  }
  &-img {
    position: absolute;
    height: 100%;
    width: 120rem;
    top: 0;
    left: 0;
    margin: 0 -22.5rem;
  }
  .tovideo {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: @px40;
    // width: calc(220 / 1920 * 100vw);
    // height: calc(64 / 1920 * 100vw);
    width: @px220;
    height: @px64;
    border-radius: @px32;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    font-size: @px14;
    line-height: @px24;
    &-img {
      width: @px32;
      margin-left: @px38;
      margin-right: @px16;
    }
    &-font {
      font-size: @px14;
    }
  }
}
.introduce {
  overflow: hidden;
  width: @px1200;
  position: relative;
  margin: @px40 auto @px80;
  background: url('//bosdev.liangyihui.net/temp/1617351337.png') no-repeat;
  background-size: 100% 100%;
  color: #454556;
}
.maintitle {
  font-size: @px32;
  line-height: @px40;
  font-weight: bold;
  margin-top: @px40;
}
.title {
  margin-top: @px40;
  font-size: @px20;
  line-height: @px40;
  font-weight: bold;
}
.message {
  font-size: @px16;
  line-height: @px36;
  margin-top: @px12;
  width: @px680;
  margin-bottom: @px40;
}
.parttitle {
  margin: 0 auto;
  font-size: @px32;
  line-height: @px40;
  color: #454556;
  width: @px1200;
  font-weight: bold;
  text-align: center;
  margin-bottom: @px40;
}
.mvbox {
  width: 100%;
  height: @px140;
  margin-bottom: @px80;
  &-content {
    background: url('//bosdev.liangyihui.net/temp/1619175513.png') no-repeat;
    background-size: 100% 100%;
    height: 100%;
    width: @px1200;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    &-style {
      display: flex;
      align-items: center;
      .icon {
        width: @px60;
        height: @px60;
        margin-right: @px24;
      }
    }
  }
}
.mvtitle {
  line-height: @px32;
  font-weight: bold;
  font-size: @px24;
  color: #454556;
  margin-bottom: @px4;
}
.mvdetail {
  line-height: @px24;
  font-size: @px16;
  color: #454556;
}
.culture {
  width: @px1200;
  margin: 0 auto @px80;
  display: flex;
  flex-wrap: wrap;
  &-box {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: @px600;
    height: @px336;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    &-title {
      font-size: @px36;
      line-height: @px54;
      color: #ffffff;
      font-weight: bold;
    }
    &-content {
      font-size: @px24;
      line-height: @px32;
      color: #ffffff;
      margin-top: @px20;
    }
  }
}
.eventspart {
  overflow: hidden;
  background-image: url('//bosdev.liangyihui.net/temp/1617356613.png');
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: 120rem @px248;
  min-width: @px1200;
  width: 100%;
  max-width: 120rem;
  background-repeat: no-repeat;
  height: @px248;
  margin: 0 auto @px42;
  color: #ffffff;
  &-box {
    margin: 0 auto;
    width: @px1200;
  }
  &-title {
    font-weight: bold;
    font-size: @px32;
    line-height: @px40;
    margin-top: @px40;
    text-align: center;
  }
  .rprogress {
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: @px860;
    font-size: @px16;
    line-height: @px24;
    color: rgba(255, 255, 255, 0.5);
    margin: @px40 auto 0;
    .point {
      cursor: pointer;
    }
    .ballcontent {
      cursor: pointer;
    }
  }
}
.ball {
  width: @px20;
  height: @px20;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.ballcontent {
  width: @px16;
  height: @px16;
  border-radius: 50%;
}
.line {
  width: @px100;
  height: @px2;
  flex-grow: 1;
  background-color: rgba(255, 255, 255, 0.5);
}
.ballbox {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
}
.substance {
  width: @px1200;
  margin: @px42 auto @px40;
}
.timebox {
  vertical-align: top;
  font-size: @px16;
  line-height: @px24;
  display: inline-block;
  color: #454556;
}
.contentboxhome {
  font-size: @px16;
  line-height: @px24;
  color: #454556;
  width: @px240;
  margin-left: @px20;
  display: inline-block;
}
.line1 {
  background-color: #e5e5e8;
  width: @px320;
  height: @px1;
  margin: @px20 0;
}
.eventbox {
  margin-bottom: @px16;
}
.timetitle {
  color: #454556;
  font-size: @px24;
  line-height: @px32;
  font-weight: bold;
}
.partners {
  margin-top: @px80;
  &-imgbox {
    margin: @px40 auto @px12;
    width: @px1200;
    &-img {
      width: 100%;
    }
  }
  &-footer{
    font-size: @px16;
    line-height: @px24;
    color: rgba(152, 152, 162, 1);
    margin-bottom: @px80;
    text-align: center
  }
}
.mbhome {
  text-align: left;
  width: 100%;
}
.mbbanner {
  margin-top: @px60;
  width: 100%;
  &-img {
    width: 100%;
  }
}
.mbmaintitle {
  font-weight: bold;
  margin: @px40 0 @px11 @px20;
  font-size: @px18;
  line-height: @px26;
  color: #454556;
}
.mbintroduce {
  width: 100%;
  background-image: url('//bosdev.liangyihui.net/temp/1618823837.png');
  background-size: 100% 100%;
  padding: @px5 @px20 @px21 @px20;
  box-sizing: border-box;
  .mbtitle {
    font-size: @px15;
    line-height: @px22;
    color: #454556;
    margin-bottom: @px8;
    font-weight: bold;
  }
  .mbmessage {
    text-align: justify;
    font-size: @px14;
    line-height: @px20;
    color: #454556;
  }
}
.mbparttitle {
  margin: @px40 auto @px16;
  font-size: @px18;
  line-height: @px26;
  font-weight: bold;
  color: #454556;
  text-align: center;
}
.mbmvbox {
  background-image: url('//bosdev.liangyihui.net/temp/1618825305.png');
  padding: @px13 0 @px19;
  box-sizing: border-box;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin: 0 auto @px40;
  width: e('calc( 100vw - 2.5rem)');
  .mbmvbox-content-style {
    overflow: hidden;
  }
  .mbicon {
    width: @px30;
    height: @px30;
    margin-top: @px7;
    margin-right: @px12;
    float: left;
  }
  .mbbox {
    float: left;
    .mbmvtitle {
      font-size: @px16;
      line-height: @px24;
      font-weight: bold;
      color: #454556;
    }
    .mbmvdetail {
      font-size: @px12;
      line-height: @px18;
      color: #454556;
      margin-top: @px2;
    }
  }
}
.mbculture {
  width: e('calc( 100vw - 2.5rem)');
  margin: 0 auto;
  &-box {
    height: @px188;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    overflow: hidden;
    margin-bottom: @px8;
    &-title {
      margin-top: @px67;
      font-size: @px18;
      line-height: @px26;
      font-weight: bold;
      color: #ffffff;
      margin-bottom: @px8;
      text-align: center;
    }
    &-content {
      font-size: @px14;
      line-height: @px20;
      color: #ffffff;
      text-align: center;
    }
  }
}
.mbrprogress {
  padding: @px11 0 0.668rem @px20;
  width: @px375;
  border-bottom: 1px solid #e5e5e8;
  position: relative;
  color: #9898a2;
  box-sizing: border-box;
  display: flex;
  justify-items: center;
  line-height: @px22;
  overflow-x: scroll;
  &-box {
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: @px24;
    font-weight: bold;
    position: relative;
  }
  .mbline {
    background-color: #cd0070;
    width: @px30;
    height: @px2;
    border-radius: @px1;
    position: absolute;
    bottom: -0.668rem;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}
.mbselectbox {
  margin-bottom: @px16;
}
.mbeventbox {
  padding: @px20;
  .mbtimebox {
    vertical-align: top;
    font-size: @px14;
    line-height: @px20;
    display: inline-block;
    color: #454556;
  }
  .mbcontentboxhome {
    width: e('calc(100vw - 95px)');
    font-size: @px14;
    line-height: @px20;
    color: #454556;
    margin-left: @px15;
    display: inline-block;
  }
}
.mbpartners {
  width: e('calc(100vw - 40px)');
  margin: 0 auto @px8;
  img {
    width: 100%;
  }
}
.mbtap{
  font-size: @px12;
  line-height: @px18;
  color: rgba(152, 152, 162, 1);
  padding-bottom: @px40;
  text-align: center
}
.swiper-container {
  cursor: pointer;
}
</style>
